<template>
  <a
    @click="login"
    class="sns-item facebook"
  >{{message}}</a>
</template>

<script>
export default {
  name: 'FacebookLogin',
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => value.match(/(signIn|signUp)/),
    },
  },
  computed: {
    message() {
      if (this.type === 'signIn') {
        return this.$t('content.auth.FacebookLogin.ButtonSignIn');
      } if (this.type === 'signUp') {
        return this.$t('content.auth.FacebookLogin.ButtonSignUp');
      }
      return '';
    },
  },
  mounted() {
    if (!window.FB) {
      // Facebook SDK for JavaScript : https://developers.facebook.com/docs/javascript/quickstart
      // Facebook Login for the Web with the JavaScript SDK : https://developers.facebook.com/docs/facebook-login/web
      const script = document.createElement('script');
      script.setAttribute('src', 'https://connect.facebook.net/en_US/sdk.js');
      script.addEventListener('load', () => {
        this.initFBAuth();
      });

      document.head.appendChild(script);
    }
  },
  methods: {
    initFBAuth() {
      window.FB.init({
        appId: process.env.VUE_APP_FACEBOOK_CLIENT_ID,
        cookie: true,
        xfbml: true,
        version: 'v8.0',
      });
    },
    login() {
      if (window.FB) {
        window.FB.login((res) => {
          if (res.authResponse) {
            const url = '/me';
            window.FB.api(url, 'get', { fields: 'name,email' }, (user) => {
              const userInfo = {
                email: user.email,
                name: user.name,
                snsName: this.$t('sns.facebook'),
                apply_path: '1104',
                access_token: res.authResponse.accessToken,
              };
              /**
               * 인증 이벤트
               */
              this.$emit('authenticated', userInfo);
            });
          }
        }, { scope: 'email' });
      }
    },
  },
};
</script>

<style scoped>
</style>
