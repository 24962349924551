<template>
  <a
    @click="login"
    class="sns-item naver"
  >{{message}}</a>
</template>

<script>
import routeTypes from '@/router/routeTypes';

export default {
  name: 'NaverLogin',
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => value.match(/(signIn|signUp)/),
    },
  },
  data() {
    return {
      naverLogin: null,
    };
  },
  computed: {
    message() {
      if (this.type === 'signIn') {
        return this.$t('content.auth.NaverLogin.ButtonSignIn');
      } if (this.type === 'signUp') {
        return this.$t('content.auth.NaverLogin.ButtonSignUp');
      }
      return '';
    },
  },
  mounted() {
    if (!window.naver) {
      // 네이버 아이디로 로그인 SDK : https://developers.naver.com/docs/login/sdks/ , https://developers.naver.com/docs/login/web/
      // SAMPLE PAGE : http://static.nid.naver.com/oauth/sample/javascript_sample.html
      const script = document.createElement('script');
      script.setAttribute('src', 'https://static.nid.naver.com/js/naveridlogin_js_sdk_2.0.0.js');
      script.addEventListener('load', () => {
        this.initNaverLogin();
      });

      document.head.appendChild(script);
    }
  },
  methods: {
    initNaverLogin() {
      let path = '';
      if (this.type === 'signIn') {
        path = routeTypes.ROUTE_PATH_NAVER_CALLBACK_SIGNIN;
      } else if (this.type === 'signUp') {
        path = routeTypes.ROUTE_PATH_NAVER_CALLBACK_SIGNUP;
      }

      this.naverLogin = new window.naver.LoginWithNaverId({
        clientId: process.env.VUE_APP_NAVER_CLIENT_ID,
        callbackUrl: window.location.origin + path,
      });

      this.naverLogin.init();
    },
    login() {
      if (window.naver) {
        if (!this.naverLogin) {
          this.initNaverLogin();
        }

        this.naverLogin.authorize();
      }
    },
  },
};
</script>

<style scoped>
</style>
