<template>
  <a
    @click="login"
    class="sns-item kakao"
  >{{message}}</a>
</template>

<script>
export default {
  name: 'KakaoLogin',
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => value.match(/(signIn|signUp)/),
    },
  },
  computed: {
    message() {
      if (this.type === 'signIn') {
        return this.$t('content.auth.KakaoLogin.ButtonSignIn');
      } if (this.type === 'signUp') {
        return this.$t('content.auth.KakaoLogin.ButtonSignUp');
      }
      return '';
    },
  },
  mounted() {
    if (!Kakao || !Kakao.isInitialized()) {
      const script = document.createElement('script');
      script.setAttribute('src', 'https://t1.kakaocdn.net/kakao_js_sdk/2.1.0/kakao.min.js');
      script.addEventListener('load', () => {
        Kakao.init(process.env.VUE_APP_KAKAO_CLIENT_ID);
      });
      document.head.appendChild(script);
      console.log('Kakao.isInitialized();', Kakao.isInitialized())
    }
  },
  methods: {
    login() {
      Kakao.Auth.authorize({
        redirectUri: `${window.location.protocol}//${window.location.host}/oauth/kakao?type=${this.type}`,
        scope: 'account_email, profile_nickname',
      });
    }
  },
};
</script>

<style scoped>
.sns-sign > li .kakao {
    background-color: #fee500;
    color: #00000090 !important;
    background-position: 0 -16.8rem;
}
.sns-sign > li .kakao:hover {
    background-color: #fee500;
    color: #000000;
    box-shadow: 0 10px 14px 0 rgba(0, 0, 0, 0.22), 0 2px 3px 0 rgba(0, 0, 0, 0.18);
}
.sns-sign > li .kakao:before {
    background: #fee500 url("../../assets/images/icons/logo-kakao.png") no-repeat center center;
}
</style>
