<template>
  <a
    @click="login"
    class="sns-item google"
  >{{message}}</a>
</template>

<script>
export default {
  name: 'GoogleLogin',
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => value.match(/(signIn|signUp)/),
    },
  },
  data() {
    return {
      googleAuth: null,
    };
  },
  computed: {
    message() {
      if (this.type === 'signIn') {
        return this.$t('content.auth.GoogleLogin.ButtonSignIn');
      } if (this.type === 'signUp') {
        return this.$t('content.auth.GoogleLogin.ButtonSignUp');
      }
      return '';
    },
  },
  methods: {
    login() {
      // Google's OAuth 2.0 endpoint for requesting an access token
      var oauth2Endpoint = 'https://accounts.google.com/o/oauth2/v2/auth';

      // Create <form> element to submit parameters to OAuth 2.0 endpoint.
      var form = document.createElement('form');
      form.setAttribute('method', 'POST'); // Send as a GET request.
      form.setAttribute('action', oauth2Endpoint);

      // Parameters to pass to OAuth 2.0 endpoint.
      var params = {
        'client_id': process.env.VUE_APP_GOOGLE_CLIENT_ID,
        'redirect_uri': `${window.location.protocol}//${window.location.host}/oauth/google/${this.type}`,
        'response_type': 'token',
        'scope': 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile',
        'include_granted_scopes': 'true',
        'state': 'pass-through value'
      };

      // Add form parameters as hidden input values.
      for (var p in params) {
        var input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', p);
        input.setAttribute('value', params[p]);
        form.appendChild(input);
      }

      // Add form to page and submit it to open the OAuth 2.0 endpoint.
      document.body.appendChild(form);
      form.submit();
    },
  },
};
</script>

<style scoped>
</style>
